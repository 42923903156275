:root {
    --eko-dark-bg-primary: #325456;
    --eko-box-background: #fff;
    --eko-dark-title: #BDECC1;
    --eko-input-border-color: #E8E8E8;
    --gap-1: 5px;
    --gap-2: 10px;
    --gap-3: 15px;
    --gap-4: 20px;
    --border-radius-s: 5px;
    --border-radius-l: 15px;


    --eko-font-size: 18px;
    --eko-font-size-mobile: 14px;

    --eko-border-radius: 10px;
    --eko-border-radius-medium: 5px;
    --eko-border-radius-small: 3px;
    --sat: env(safe-area-inset-top);
    /* region general colors -------------------------------------------------- */

    /* Palette */
    --eko-primary-color: #5FA776;
    --eko-primary-color-light: #C3DECC;
    --eko-primary-color-light-2: #E4F0E8;
    --eko-primary-color-light-3: #9BC8A9;
    --eko-primary-color-light-4: #EBF4EE;
    --eko-primary-color-light-5: #D7E9DD;
    --eko-primary-color-light-6: #F3F8F5;
    --eko-primary-color-dark: #26733F;
    --eko-secondary-color: #FFF;
    --eko-third-color: #6A6D74;
    --eko-third-color-light: #6A6D74;
    --eko-third-color-light-2: #6a6d740a;
    --eko-third-color-light-3: #E5EBEC;
    --eko-third-color-dark: #6A6D7480;
    --eko-third-color-dark-2: #6A6D7433;
    --eko-fourth-color: #171B24;
    --eko-fifth-color: #E5EBEB;
    --eko-sixth-color: #5F73A1;
    --eko-additional-accent-1: #0F5AB1;
    --eko-additional-accent-2: #FBBD00;
    --eko-additional-accent-3: #EE737E;
    --eko-additional-accent-4: #F7710F;
    --eko-error-color: #D40D0D;
    --eko-error-color-2: #D40D0DAB;
    --eko-background-color: #FBFAF5;

    --eko-link-color: #61dafb;

    /* Text */
    --eko-title-color: var(--eko-fourth-color);
    --eko-text-color: var(--eko-third-color);
    --eko-text-primary-color: var(--eko-secondary-color);

    /* Blocks */
    --eko-block-background-color: var(--eko-third-color);
    --eko-block-background-primary-color: var(--eko-primary-color);
    --eko-card-background-color: var(--eko-secondary-color);
    --eko-card-background-primary-color: var(--eko-primary-color);
    --eko-card-background-primary-2-color: var(--eko-fourth-color);
    --eko-card-background-secondary-color: var(--eko-fifth-color);
    /* TODO: add post css rule if exists */
    --eko-page-background-color: var(--eko-fourth-color) 50%;
    --eko-popup-card-background-color: var(--eko-background-color);

    --eko-header-bg-color-integrated: var(--eko-primary-color-light-6);
    --eko-header-bg-color-general: var(--eko-primary-color);
    --eko-header-menu-item-active-bg: #E4F0E8;
}
